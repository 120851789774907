import React from 'react';
import { withRouter } from 'react-router-dom'

import Background from './components/Background';

const DnD = withRouter(({ history }) => {

    return <>
      <Background>
        <div>dnd</div>
      </Background>
    </>
  });

  export default DnD;
