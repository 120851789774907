import React from 'react';
import styled from 'styled-components';

import Background from './components/Background'
import BuildPlayer from './BuildPlayer';

const Suspense = ({commits}) => {

    const regex = new RegExp("^v[0-9]+\\.",'g');
    const versionCommits = commits.filter((commit) => {
        return commit.message.match(regex);
    });

    const latestBuild = versionCommits.length > 0 ?
        <>
            <SectionHeader>
                LATEST
            </SectionHeader>
            <CommitInfo>
                {versionCommits[0].message}
            </CommitInfo>
            <BuildPlayerWrapper>
                <BuildPlayer hash={versionCommits[0].hash}/>
            </BuildPlayerWrapper>
        </> :
        "";
    
    const pastCommits = versionCommits.slice(1);

    const pastBuilds = pastCommits.length > 0 ?
        <>
            <SectionHeader>
                PAST BUILDS
            </SectionHeader>
            {
                pastCommits.map((commit) => (
                    <>
                        <CommitInfo>
                            {commit.message}
                        </CommitInfo>
                        <BuildPlayerWrapper>
                            <BuildPlayer hash={commit.hash}/>
                        </BuildPlayerWrapper>
                    </> 
                ))
            }
        </> :
        "";
    
    return <>
        <Background>
            <Header>
                <Title>
                    suspense
                </Title>
                <TextLink href={'https://github.com/tylerhekman/suspense'}>
                    github.com/tylerhekman/suspense
                </TextLink>
                {latestBuild}
                {pastBuilds}
            </Header>
        </Background>
    </>
};

const Header = styled.div`
    padding-top: 16px;
    padding-left 16px;
`

const Title = styled.div`
  color: #DDDDDD
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  user-select: ${props => props.onClick ? 'auto' : 'none'}
`
const TextLink = styled.a`
    font-family: 'Roboto Slab', cursive;
    display: block;
    margin-top: 8px;
    color: #DDDDDD
    font-size: 16px;
`

const SectionHeader = styled.div`
    font-family: 'Roboto Slab', cursive;
    display: block;
    margin-top: 64px;
    color: #DDDDDD
    font-size: 48px;
`

const CommitInfo = styled.div`
    font-family: 'Roboto Slab', cursive;
    display: block;
    margin-top: 16px;
    color: #DDDDDD
    font-size: 16px;
`

const BuildPlayerWrapper = styled.div`
    margin-top: 8px;
`

export default Suspense;