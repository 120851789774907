import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './Home';
import Suspense from './Suspense';
import DnD from './DnD';

const App = () => {

  const [commits, setCommits] = useState([]);

  const fetchCommits = async () => {
      const response = await fetch('https://api.github.com/repos/tylerhekman/suspense/commits');
      const commits = await response.json();
      const processedCommits = commits.map((commit) => ({hash: commit.sha, message: commit.commit.message}));
      setCommits(processedCommits);
  };

  if(commits.length === 0) {
      fetchCommits();
  }

  return <>
    <link href='https://fonts.googleapis.com/css?family=Montserrat&display=swap' rel='stylesheet' type='text/css'/>
    <link href='https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap' rel='stylesheet' type='text/css'/>

    <Router>
      <Route path='/' exact component={Home} />
      <Route 
        path='/suspense'
        render={(props) => <Suspense {...props} commits={commits} />}
      />
      <Route path='/dnd' exact component={DnD} />
    </Router>
  </>
};

export default App;
