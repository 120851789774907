import styled from 'styled-components';

const Background = styled.div`
  background-color: #222222;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
`;

export default Background;