import React from 'react';

const BuildPlayer = ({hash}) => {
    return <iframe
        title={hash}
        src={`https://s3.amazonaws.com/tylerhekman.com/builds/${hash}/index.html`}
        name="suspense"
        scrolling="no"
        style={{border: "0px #000000 none"}}
        allowFullScreen={true}
        height="40px"
        width="230px">
    </iframe>
};

export default BuildPlayer;