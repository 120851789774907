import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { withRouter } from 'react-router-dom'

import logo from './resources/avatar-transparent.png';

import Background from './components/Background';

const Home = withRouter(({ history }) => {

  const [initialized, setInitialized] = useState(false);
  const [title, setTitle] = useState("");
  const [animationComplete, setAnimationComplete] = useState(false);

  const fullTitle = 'suspense'

  if(initialized && fullTitle !== title) {
    setTimeout(() => {
      setTitle(fullTitle.slice(0, title.length + 1));
    }, 100);
  }

  if(!animationComplete && fullTitle === title) {
    setTimeout(() => {
      setAnimationComplete(true);
    }, 100);
  }

  return <>
    <Background>
      <CenteredComponent>
        <VerticalWrapper>
          <LogoCircle onClick={() => setInitialized(true)} initialized={initialized}>
            <Logo src={logo} alt={'LOGO'} initialized={initialized}/>
          </LogoCircle>
          <HorizontalWrapper visibile={initialized}>
            <TitleLink onClick={() => {if(animationComplete) {history.push('/suspense')}}} animationComplete={animationComplete}>
              {title}
            </TitleLink>
            <Blink>
              <TitleLink>
                |
              </TitleLink>
            </Blink>
          </HorizontalWrapper>
        </VerticalWrapper>
      </CenteredComponent>
    </Background>
  </>
});

const CenteredComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const LogoCircle = styled.div`
  background-color: #DDDDDD;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  transition: .8s;
  cursor: ${props => props.initialized ? 'default' : 'pointer'}
  filter: ${props => props.initialized ? 'drop-shadow(0px 8px 8px black)' : 'none'};
  &:hover {
    filter: drop-shadow(0px 8px 8px black);
  }
`
const Logo = styled.img`
  height: 400px;
  width: 400px;
  transition: .8s;
  filter: ${props => props.initialized ? 'none' : 'grayscale(100%) blur(2px)'};
  &:hover {
    filter: none;
  }
`

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 12px;
  visibility: ${props => props.visibile ? 'visibile' : 'hidden'}
`

const TitleLink = styled.div`
  color: #DDDDDD
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  margin-top: 64px;
  cursor: ${props => props.onClick && props.animationComplete ? 'pointer' : 'default'};
  user-select: ${props => props.onClick ? 'auto' : 'none'}
  text-decoration: ${props => props.onClick && props.animationComplete ? 'underline' : 'none'}
`

const blink = keyframes`
  to {
    visibility: hidden;
  }
`;

const Blink = styled.div`
  animation: ${blink} 1s steps(2, start) infinite;
`
export default Home;